/**
 * @ Author: Your name
 * @ Create Time: 2023-07-10 09:46:53
 * @ Modified by: Your name
 * @ Modified time: 2023-07-10 09:47:06
 * @ Description:搜索栏样式
 */

.search-row-1 {
  height: 50px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-left: 16px;
}

.search-row-2 {
  height: 50px;
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
  padding-left: 16px;

  .title {
    display: flex;
    align-items: center;
  }

  .sub_title {
    font-size: 12px;
    color: #71757D;
  }
}