/**
 * @ Author: Your name
 * @ Create Time: 2023-07-10 09:37:32
 * @ Modified by: Your name
 * @ Modified time: 2024-11-26 11:29:35
 * @ Description: 基础样式 
 */
a {
  color: @link-color;
}

.copy {
  color: #4B7CF4;
  cursor: pointer;
}

.bule {
  color: #0070ff;
  cursor: pointer;
}

.del {
  color: @error-color  !important;
  cursor: pointer;
}

.edit {
  color: @link-color;
  margin-right: 10px;
  cursor: pointer;
}

.default {
  color: @text-color-secondary  !important;
  cursor: pointer;
}


.primary-color {
  color: @primary-color;
  cursor: pointer;
}

.bule_btn {
  background: #4B7CF4;
  border: 1px solid #4B7CF4;
}

.bule_btn:hover {
  background: #4B7CF4;
  border: 1px solid #4B7CF4;
}

.bule_btn:active {
  background: #4B7CF4;
  border: 1px solid #4B7CF4;
}

// 说明tip
.popoverText {
  font-size: 12px;
  color: #252525;

  >div {
    margin-bottom: 5px;
  }

  .desc {
    color: #71757D;
    font-size: 12px;
  }

}

// 异常事件 客服报表-异常明细 popover样式 
.abnormal_list_info {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;


  .time {
    color: @text-color-secondary;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .enterprise {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .person_info {
    color: @text-color-secondary;
    font-size: 12px;
    margin-bottom: 5px;

    span {
      display: block;
    }
  }
}

.abnormal_list_info_height {
  max-height: 400px;
  overflow-y: auto;
  max-width: 400px;
}

.abnormal_list_info_row_height {
  height: 130px;
  overflow-y: hidden;
}