@font-face {
    // 加粗
    font-family: "OPPOSans-B";
    src: url('./OPPOSans-B.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    // 重粗
    font-family: "OPPOSans-H";
    src: url('./OPPOSans-H.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    // 高亮
    font-family: "OPPOSans-L";
    src: url('./OPPOSans-L.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    // 中等
    font-family: "OPPOSans-M";
    src: url('./OPPOSans-M.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    // 正常字体
    font-family: "OPPOSans-R";
    src: url('./OPPOSans-R.ttf');
    font-weight: normal;
    font-style: normal;
}
@primary-color: #FB9424;@bg-color: #F3F3F5;@link-color: #FB9424;@success-color: #37C879;@warning-color: #faad14;@error-color: #FD665D;@font-size-base: 14px;@heading-color: #252525;@text-color: #252525;@text-color-secondary: #71757D;@disabled-color: #DADAE0;@border-radius-base: 2px;@border-color-base: rgba(202, 204, 209, 0.6000);