/**
 * @ Author: Your name
 * @ Create Time: 2023-07-10 09:52:22
 * @ Modified by: Your name
 * @ Modified time: 2024-07-08 15:25:36
 * @ Description:
 */

.font-weight-bold {
  font-weight: bold;
}

.font-size-10 {
  font-size: 10px;
  transform: scale(0.83333);
  transform-origin: 0 0;
}

.font-size-9 {
  font-size: 9px;
  transform: scale(0.75);
  transform-origin: 0 0;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-14 {
  font-size: 14px !important;
}

.font-size-24 {
  font-size: 24px !important;
}

.font-size-26 {
  font-size: 26px !important;
}

.font-size-32 {
  font-size: 32px !important;
}

.font-size-36 {
  font-size: 36px !important;

}

.font-size-38 {
  font-size: 38px !important;
}

.font-size-40 {
  font-size: 40px;
}

.font-size-46 {
  font-size: 46px;
}

.font-size-48 {
  font-size: 48px;
}

.font-size-50 {
  font-size: 50px;
}

.font-size-54 {
  font-size: 54px;
}

.font-size-28 {
  font-size: 28px !important;

}