/*整个滚动条*/
::-webkit-scrollbar {
  width: 8px;
  background-color: #fff;
}

/*水平的整个滚动条*/
::-webkit-scrollbar:horizontal {
  height: 8px;
  background-color: #71757d;
}

/*滚动条轨道*/
::-webkit-scrollbar-track {
  background-color: #f3f4f7;
}

/*竖直的滑块*/
::-webkit-scrollbar-thumb {
  background-color: #71757d;
  border-radius: 4px;
}