/**
 * @ Author: Your name
 * @ Create Time: 2023-07-10 09:42:39
 * @ Modified by: Your name
 * @ Modified time: 2024-06-12 17:13:05
 * @ Description: 外边距样式
 */

.margin-left-5 {
  margin-left: 5px;

}

.margin-left-20 {
  margin-left: 20px;
}

.margin-left-16 {
  margin-left: 16px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-bottom-0 {
  margin-bottom: 0px !important;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-12 {
  margin-top: 12px;
}

.margin-bottom-12 {
  margin-bottom: 12px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}