/**
 * @ Author: caohenghui
 * @ Create Time: 2021-09-08 17:11:15
 * @ Modified by: Your name
 * @ Modified time: 2024-11-07 10:54:25
 * @ Description: 
 */
/**
 * @ Author: Your name
 * @ Create Time: 2023-07-10 09:34:52
 * @ Modified by: Your name
 * @ Modified time: 2024-10-28 15:37:15
 * @ Description: 表单项宽度
 */
.form-width-30 {
  width: 30px !important;
}
.form-width-55 {
  display: inline-block;
  width: 55px !important;
}
.form-width-65 {
  width: 65px !important;
}
.form-width-85 {
  width: 85px !important;
}
.form-width-110 {
  width: 110px !important;
}
.form-width-120 {
  width: 120px !important;
}
.form-width-128 {
  width: 128px !important;
}
.form-width-148 {
  width: 148px !important;
}
.form-width-160 {
  width: 160px !important;
}
.form-width-170 {
  width: 160px !important;
}
.form-width-180 {
  width: 180px !important;
}
.form-width-187 {
  width: 187px !important;
}
.form-width-200 {
  width: 200px !important;
}
.form-width-220 {
  width: 220px !important;
}
.form-width-225 {
  width: 225px !important;
}
.form-width-230 {
  width: 230px !important;
}
.form-width-240 {
  width: 240px !important;
}
.form-width-260 {
  width: 250px !important;
}
.form-width-270 {
  width: 270px !important;
}
.form-width-300 {
  width: 300px !important;
}
.form-width-330 {
  width: 330px !important;
}
.form-width-370 {
  width: 370px !important;
}
.form-width-380 {
  width: 380px !important;
}
.form-width-420 {
  width: 420px !important;
}
.form-width-500 {
  width: 500px !important;
}
.form-width-600 {
  width: 600px !important;
}
.form-width-530 {
  width: 530px !important;
}
.form-width-640 {
  width: 640px !important;
}
.form-width-840 {
  width: 840px !important;
}
.form-width-500 {
  width: 500px !important;
}
.form-inline-width {
  width: 150px !important;
}
.form-inline-width-100 {
  width: 100px !important;
}
.form-inline-width-120 {
  width: 120px !important;
}
.form-inline-width-250 {
  width: 250px !important;
}
.form-inline-width-180 {
  width: 180px !important;
}
.form-inline-width-300 {
  width: 300px !important;
}
.form-inline-width-210 {
  width: 214px !important;
}
.form-inline-width-260 {
  width: 260px !important;
}
/*整个滚动条*/
::-webkit-scrollbar {
  width: 8px;
  background-color: #fff;
}
/*水平的整个滚动条*/
::-webkit-scrollbar:horizontal {
  height: 8px;
  background-color: #71757d;
}
/*滚动条轨道*/
::-webkit-scrollbar-track {
  background-color: #f3f4f7;
}
/*竖直的滑块*/
::-webkit-scrollbar-thumb {
  background-color: #71757d;
  border-radius: 4px;
}
/**
 * @ Author: Your name
 * @ Create Time: 2023-07-10 09:37:32
 * @ Modified by: Your name
 * @ Modified time: 2024-11-26 11:29:35
 * @ Description: 基础样式 
 */
a {
  color: #FB9424;
}
.copy {
  color: #4B7CF4;
  cursor: pointer;
}
.bule {
  color: #0070ff;
  cursor: pointer;
}
.del {
  color: #FD665D !important;
  cursor: pointer;
}
.edit {
  color: #FB9424;
  margin-right: 10px;
  cursor: pointer;
}
.default {
  color: #71757D !important;
  cursor: pointer;
}
.primary-color {
  color: #FB9424;
  cursor: pointer;
}
.bule_btn {
  background: #4B7CF4;
  border: 1px solid #4B7CF4;
}
.bule_btn:hover {
  background: #4B7CF4;
  border: 1px solid #4B7CF4;
}
.bule_btn:active {
  background: #4B7CF4;
  border: 1px solid #4B7CF4;
}
.popoverText {
  font-size: 12px;
  color: #252525;
}
.popoverText > div {
  margin-bottom: 5px;
}
.popoverText .desc {
  color: #71757D;
  font-size: 12px;
}
.abnormal_list_info {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}
.abnormal_list_info .time {
  color: #71757D;
  font-weight: 500;
  margin-bottom: 5px;
}
.abnormal_list_info .enterprise {
  font-weight: bold;
  margin-bottom: 5px;
}
.abnormal_list_info .person_info {
  color: #71757D;
  font-size: 12px;
  margin-bottom: 5px;
}
.abnormal_list_info .person_info span {
  display: block;
}
.abnormal_list_info_height {
  max-height: 400px;
  overflow-y: auto;
  max-width: 400px;
}
.abnormal_list_info_row_height {
  height: 130px;
  overflow-y: hidden;
}
/**
 * @ Author: Your name
 * @ Create Time: 2023-07-10 09:45:37
 * @ Modified by: Your name
 * @ Modified time: 2024-05-21 11:07:01
 * @ Description: 超出省略
 */
.ellipsis {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
.ellipsis-2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.ellipsis-3 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.ellipsis-4 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
.ellipsis-5 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
}
.ellipsis-6 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 6;
  line-clamp: 6;
  -webkit-box-orient: vertical;
}
/**
 * @ Author: Your name
 * @ Create Time: 2023-07-10 09:46:53
 * @ Modified by: Your name
 * @ Modified time: 2023-07-10 09:47:06
 * @ Description:搜索栏样式
 */
.search-row-1 {
  height: 50px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-left: 16px;
}
.search-row-2 {
  height: 50px;
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 16px;
  padding-left: 16px;
}
.search-row-2 .title {
  display: flex;
  align-items: center;
}
.search-row-2 .sub_title {
  font-size: 12px;
  color: #71757D;
}
/**
 * @ Author: Your name
 * @ Create Time: 2023-07-10 09:47:37
 * @ Modified by: Your name
 * @ Modified time: 2023-12-21 19:40:14
 * @ Description: 重置组件样式
 */
.custom_tabs_styles .ant-tabs-nav {
  padding-left: 16px;
  margin-bottom: 0 !important;
}
.custom_tabs_styles .padding_left_16 {
  padding: 0 16px;
}
.custom_tabs_styles .custom_radio .ant-checkbox-inner {
  border-radius: 50% !important;
}
.custom_tabs_styles .ant-input::placeholder {
  color: #A3A4A6 !important;
}
.custom_tabs_styles .ant-input-affix-wrapper {
  border: 1px solid #DEDEDE;
}
.custom_table {
  padding: 0 16px;
}
#customTable .ant-table-placeholder {
  height: 100px;
}
.custome-label .label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 3px;
}
.ant-menu-sub {
  background: #2C2C2C !important;
}
.no-colon .ant-form-item-label > label::after {
  content: "";
}
.ant-input[disabled],
.ant-input-number-disabled {
  color: rgba(102, 102, 102) !important;
  background-color: rgba(204, 204, 204, 0.2) !important;
}
.ant-picker-input > input[disabled] {
  color: rgba(102, 102, 102) !important;
  background: rgba(204, 204, 204, 0.2) !important;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(102, 102, 102) !important;
  background-color: rgba(204, 204, 204, 0.2) !important;
}
.ant-tree-checkbox-inner,
.ant-checkbox-inner {
  border-radius: 0 !important;
}
/**
 * @ Author: Your name
 * @ Create Time: 2023-07-10 09:50:49
 * @ Modified by: Your name
 * @ Modified time: 2024-07-23 09:50:58
 * @ Description:
 */
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
}
.flex-justify-content {
  display: flex;
  justify-content: space-between;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cursor {
  cursor: pointer;
}
/**
 * @ Author: Your name
 * @ Create Time: 2023-07-10 09:51:08
 * @ Modified by: Your name
 * @ Modified time: 2024-09-27 17:49:40
 * @ Description: 颜色
 */
.color_1 {
  color: #FF7B40;
}
.color_2 {
  color: #FFAF32;
}
.color_3 {
  color: #4699E8;
}
.color_4 {
  color: #FB9424;
}
.color_5 {
  color: #F85A40;
}
.color_6 {
  color: #00C16E;
}
.color_7 {
  color: #A3A4A6;
}
.color_8 {
  color: #71757D;
}
.color_9 {
  color: #096dd9;
}
.color_8 {
  color: #71757D;
}
.blue_btn {
  background-color: #4699E8;
  border-color: #4699E8;
  color: #fff;
}
.bg_color_1 {
  background-color: #FF7B40;
}
.bg_color_2 {
  background-color: #FFAF32;
}
/**
 * @ Author: Your name
 * @ Create Time: 2023-07-10 09:52:22
 * @ Modified by: Your name
 * @ Modified time: 2024-07-08 15:25:36
 * @ Description:
 */
.font-weight-bold {
  font-weight: bold;
}
.font-size-10 {
  font-size: 10px;
  transform: scale(0.83333);
  transform-origin: 0 0;
}
.font-size-9 {
  font-size: 9px;
  transform: scale(0.75);
  transform-origin: 0 0;
}
.font-size-12 {
  font-size: 12px !important;
}
.font-size-14 {
  font-size: 14px !important;
}
.font-size-24 {
  font-size: 24px !important;
}
.font-size-26 {
  font-size: 26px !important;
}
.font-size-32 {
  font-size: 32px !important;
}
.font-size-36 {
  font-size: 36px !important;
}
.font-size-38 {
  font-size: 38px !important;
}
.font-size-40 {
  font-size: 40px;
}
.font-size-46 {
  font-size: 46px;
}
.font-size-48 {
  font-size: 48px;
}
.font-size-50 {
  font-size: 50px;
}
.font-size-54 {
  font-size: 54px;
}
.font-size-28 {
  font-size: 28px !important;
}
/**
 * @ Author: Your name
 * @ Create Time: 2023-07-10 09:42:39
 * @ Modified by: Your name
 * @ Modified time: 2024-06-12 17:13:05
 * @ Description: 外边距样式
 */
.margin-left-5 {
  margin-left: 5px;
}
.margin-left-20 {
  margin-left: 20px;
}
.margin-left-16 {
  margin-left: 16px;
}
.margin-left-5 {
  margin-left: 5px;
}
.margin-left-10 {
  margin-left: 10px;
}
.margin-bottom-0 {
  margin-bottom: 0px !important;
}
.margin-top-15 {
  margin-top: 15px;
}
.margin-top-20 {
  margin-top: 20px;
}
.margin-top-12 {
  margin-top: 12px;
}
.margin-bottom-12 {
  margin-bottom: 12px;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}
/**
 * @ Author: Your name
 * @ Create Time: 2023-07-10 16:21:09
 * @ Modified by: Your name
 * @ Modified time: 2023-07-10 16:24:58
 * @ Description: 定位
 */
.top-208 {
  top: 208px !important;
}
.top-204 {
  top: 204px !important;
}
.top-198 {
  top: 198px !important;
}
/**
 * @ Author: Your name
 * @ Create Time: 2024-05-09 17:05:46
 * @ Modified by: Your name
 * @ Modified time: 2024-05-09 17:51:22
 * @ Description: 菜单图标
 */
.icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  margin-top: 2px;
}
.icon-tuiguang {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/tuiguang.png') no-repeat;
  background-size: 100%;
}
.icon-yewu {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/yewu.png') no-repeat;
  background-size: 100%;
}
.icon-zhaopin {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/zhaopin.png') no-repeat;
  background-size: 100%;
}
.icon-zhibo {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/zhibo.png') no-repeat;
  background-size: 100%;
}
.icon-huodong {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/huodong.png') no-repeat;
  background-size: 100%;
}
.icon-yunfu {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/yunfu.png') no-repeat;
  background-size: 100%;
}
.icon-kefu {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/kefu.png') no-repeat;
  background-size: 100%;
}
.icon-yuzhi {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/yuzhi.png') no-repeat;
  background-size: 100%;
}
.icon-guanli {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/guanli.png') no-repeat;
  background-size: 100%;
}
.icon-hetong {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/hetong.png') no-repeat;
  background-size: 100%;
}
.icon-sufu {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/sufu.png') no-repeat;
  background-size: 100%;
}
.icon-caiwu {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/caiwu.png') no-repeat;
  background-size: 100%;
}
.icon-peizhi {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/peizhi.png') no-repeat;
  background-size: 100%;
}
.icon-yingxiao {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/yingxiao.png') no-repeat;
  background-size: 100%;
}
.icon-yunying {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/yunying.png') no-repeat;
  background-size: 100%;
}
.icon-baobiao {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/baobiao.png') no-repeat;
  background-size: 100%;
}
.icon-renshi {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/renshi.png') no-repeat;
  background-size: 100%;
}
.icon-xiaoxi {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/xiaoxi.png') no-repeat;
  background-size: 100%;
}
.icon-huiyuan {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/huiyuan.png') no-repeat;
  background-size: 100%;
}
.icon-yujing {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/yujing.png') no-repeat;
  background-size: 100%;
}
.icon-oa {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/oa.png') no-repeat;
  background-size: 100%;
}
.icon-huawu {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/huawu.png') no-repeat;
  background-size: 100%;
}
/**
 * @ Author: Your name
 * @ Create Time: 2024-06-11 13:32:18
 * @ Modified by: Your name
 * @ Modified time: 2024-07-29 14:12:29
 * @ Description: 图标
 */
i {
  display: inline-block;
}
.control_ibrary_icon {
  width: 20px;
  height: 20px;
  display: inline-block;
}
.control_ibrary_icon_text {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/text.png') no-repeat;
  background-size: 100%;
}
.control_ibrary_icon_multi_line_text {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/multiLineText.png') no-repeat;
  background-size: 100%;
}
.control_ibrary_icon_number {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/member.png') no-repeat;
  background-size: 100%;
}
.control_ibrary_icon_money {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/money.png') no-repeat;
  background-size: 100%;
}
.control_ibrary_icon_date {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/date.png') no-repeat;
  background-size: 100%;
}
.control_ibrary_icon_datetime {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/datetime.png') no-repeat;
  background-size: 100%;
}
.control_ibrary_icon_radio {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/radio.png') no-repeat;
  background-size: 100%;
}
.control_ibrary_icon_checkbox {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/checkbox.png') no-repeat;
  background-size: 100%;
}
.control_ibrary_icon_member {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/member.png') no-repeat;
  background-size: 100%;
}
.control_ibrary_icon_department {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/department.png') no-repeat;
  background-size: 100%;
}
.control_ibrary_icon_enclosure {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/enclosure.png') no-repeat;
  background-size: 100%;
}
.control_ibrary_icon_desc {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/desc.png') no-repeat;
  background-size: 100%;
}
.control_ibrary_icon_detailed {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/detailed.png') no-repeat;
  background-size: 100%;
}
.control_ibrary_icon_link {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/link.png') no-repeat;
  background-size: 100%;
}
body,
html {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: 1;
}
body .ant-tabs-content-holder,
html .ant-tabs-content-holder {
  min-height: 350px !important;
}
body .ant-modal-body,
html .ant-modal-body {
  max-height: 700px;
  overflow: auto;
  padding: 16px 30px;
}
@media screen and (max-width: 1536px) {
  body .ant-modal-body,
  html .ant-modal-body {
    max-height: 450px;
  }
}
.label-display {
  display: flex;
  align-items: center;
  padding: 10px 0;
}
.right-loading {
  width: 100vw;
  height: calc(100vh - 48px);
  background: rgba(238, 238, 238, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}
.right-loading div {
  z-index: 99;
}
.full_screen {
  width: 100vw;
  height: calc(100vh - 48px);
  background: rgba(238, 238, 238, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}
.full_screen div {
  z-index: 100;
}
.left-label {
  display: inline-block;
  width: 3px;
  height: 14px;
  background: #FB9424;
  border-radius: 2px;
  margin-right: 6px;
}
.modal_h {
  overflow-x: hidden;
  position: relative;
  max-height: 55vh;
}
body {
  margin: 0 !important;
}
.form-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.public-title {
  font-size: 16px;
  border-bottom: 1px dashed #eee;
  padding-bottom: 15px;
  font-weight: bold;
}
.mb-10 {
  padding-top: 10px;
  padding-right: 16px;
  padding-left: 16px;
  margin-bottom: 10px !important;
}
.alignRight {
  text-align: right;
}
.empty {
  margin-top: 150px;
}
.spin-position {
  position: fixed !important;
  z-index: 1000;
  left: 50%;
  top: 50%;
}
.pointer {
  cursor: pointer;
}
a {
  cursor: pointer;
}
.popover-card p {
  cursor: pointer;
}
.height_40 {
  height: 40px;
  line-height: 40px;
}
.ant-tabs-nav {
  margin-bottom: 0 !important;
}
.layout-search {
  border-bottom: 10px solid #f6f6f6;
  padding: 0px 16px 10px;
}
.layout-search .ant-form-item {
  margin-top: 10px;
}
.padding-left-0 {
  padding-left: 0;
}
.star {
  position: relative;
  padding-left: 10px;
}
.star::before {
  position: absolute;
  content: "*";
  color: red;
  left: 0;
  top: 1px;
}
