/**
 * @ Author: Your name
 * @ Create Time: 2024-06-11 13:32:18
 * @ Modified by: Your name
 * @ Modified time: 2024-07-29 14:12:29
 * @ Description: 图标
 */
i {
  display: inline-block;
}

.control_ibrary_icon {
  width: 20px;
  height: 20px;
  display: inline-block;
}

.control_ibrary_icon_text {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/text.png') no-repeat;
  background-size: 100%;
}

.control_ibrary_icon_multi_line_text {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/multiLineText.png') no-repeat;
  background-size: 100%;
}

.control_ibrary_icon_number {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/member.png') no-repeat;
  background-size: 100%;
}

.control_ibrary_icon_money {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/money.png') no-repeat;
  background-size: 100%;
}

.control_ibrary_icon_date {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/date.png') no-repeat;
  background-size: 100%;
}

.control_ibrary_icon_datetime {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/datetime.png') no-repeat;
  background-size: 100%;
}

.control_ibrary_icon_radio {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/radio.png') no-repeat;
  background-size: 100%;
}

.control_ibrary_icon_checkbox {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/checkbox.png') no-repeat;
  background-size: 100%;
}

.control_ibrary_icon_member {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/member.png') no-repeat;
  background-size: 100%;
}

.control_ibrary_icon_department {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/department.png') no-repeat;
  background-size: 100%;
}

.control_ibrary_icon_enclosure {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/enclosure.png') no-repeat;
  background-size: 100%;
}

.control_ibrary_icon_desc {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/desc.png') no-repeat;
  background-size: 100%;
}

.control_ibrary_icon_detailed {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/detailed.png') no-repeat;
  background-size: 100%;
}

.control_ibrary_icon_link {
  background: url('https://huahuiapp.obs.myhuaweicloud.com/web/controLibrary/link.png') no-repeat;
  background-size: 100%;
}