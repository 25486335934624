/**
 * @ Author: Your name
 * @ Create Time: 2024-05-09 17:05:46
 * @ Modified by: Your name
 * @ Modified time: 2024-05-09 17:51:22
 * @ Description: 菜单图标
 */
.icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  margin-top: 2px;
}

.icon-tuiguang {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/tuiguang.png') no-repeat;
  background-size: 100%;
}

.icon-yewu {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/yewu.png') no-repeat;
  background-size: 100%;
}

.icon-zhaopin {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/zhaopin.png') no-repeat;
  background-size: 100%;
}

.icon-zhibo {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/zhibo.png') no-repeat;
  background-size: 100%;
}

.icon-huodong {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/huodong.png') no-repeat;
  background-size: 100%;
}

.icon-yunfu {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/yunfu.png') no-repeat;
  background-size: 100%;
}

.icon-kefu {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/kefu.png') no-repeat;
  background-size: 100%;
}

.icon-yuzhi {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/yuzhi.png') no-repeat;
  background-size: 100%;
}

.icon-guanli {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/guanli.png') no-repeat;
  background-size: 100%;
}

.icon-hetong {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/hetong.png') no-repeat;
  background-size: 100%;
}

.icon-sufu {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/sufu.png') no-repeat;
  background-size: 100%;
}

.icon-caiwu {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/caiwu.png') no-repeat;
  background-size: 100%;
}

.icon-peizhi {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/peizhi.png') no-repeat;
  background-size: 100%;
}

.icon-yingxiao {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/yingxiao.png') no-repeat;
  background-size: 100%;
}

.icon-yunying {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/yunying.png') no-repeat;
  background-size: 100%;
}

.icon-baobiao {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/baobiao.png') no-repeat;
  background-size: 100%;
}

.icon-renshi {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/renshi.png') no-repeat;
  background-size: 100%;
}

.icon-xiaoxi {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/xiaoxi.png') no-repeat;
  background-size: 100%;
}

.icon-huiyuan {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/huiyuan.png') no-repeat;
  background-size: 100%;
}

.icon-yujing {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/yujing.png') no-repeat;
  background-size: 100%;
}

.icon-oa {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/oa.png') no-repeat;
  background-size: 100%;
}

.icon-huawu {
  background: url('https://huahuiapp.obs.cn-south-1.myhuaweicloud.com/web/huawu.png') no-repeat;
  background-size: 100%;
}