/**
 * @ Author: Your name
 * @ Create Time: 2023-07-10 09:34:52
 * @ Modified by: Your name
 * @ Modified time: 2024-10-28 15:37:15
 * @ Description: 表单项宽度
 */

.form-width-30 {
  width: 30px !important;
}

.form-width-55 {
  display: inline-block;
  width: 55px !important;
}

.form-width-65 {
  width: 65px !important;
}

.form-width-85 {
  width: 85px !important;
}

.form-width-110 {
  width: 110px !important;
}

.form-width-120 {
  width: 120px !important;
}

.form-width-128 {
  width: 128px !important;
}

.form-width-148 {
  width: 148px !important;
}

.form-width-160 {
  width: 160px !important;
}

.form-width-170 {
  width: 160px !important;
}



.form-width-180 {
  width: 180px !important;
}

.form-width-187 {
  width: 187px !important;
}

.form-width-200 {
  width: 200px !important;
}

.form-width-220 {
  width: 220px !important;
}

.form-width-225 {
  width: 225px !important;
}

.form-width-230 {
  width: 230px !important;
}

.form-width-240 {
  width: 240px !important;
}

.form-width-260 {
  width: 250px !important;
}

.form-width-270 {
  width: 270px !important;
}

.form-width-300 {
  width: 300px !important;
}

.form-width-330 {
  width: 330px !important;
}

.form-width-370 {
  width: 370px !important;
}

.form-width-380 {
  width: 380px !important;
}

.form-width-420 {
  width: 420px !important;
}

.form-width-500 {
  width: 500px !important;
}

.form-width-600 {
  width: 600px !important;
}

.form-width-530 {
  width: 530px !important;
}

.form-width-640 {
  width: 640px !important;
}

.form-width-840 {
  width: 840px !important;
}

.form-width-500 {
  width: 500px !important;
}

.form-inline-width {
  width: 150px !important;
}

.form-inline-width-100 {
  width: 100px !important;
}

.form-inline-width-120 {
  width: 120px !important;
}

.form-inline-width-250 {
  width: 250px !important;
}

.form-inline-width-180 {
  width: 180px !important;
}

.form-inline-width-300 {
  width: 300px !important;
}

.form-inline-width-210 {
  width: 214px !important;
}

.form-inline-width-260 {
  width: 260px !important;
}