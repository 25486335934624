/**
 * @ Author: Your name
 * @ Create Time: 2023-07-10 09:51:08
 * @ Modified by: Your name
 * @ Modified time: 2024-09-27 17:49:40
 * @ Description: 颜色
 */

.color_1 {
  color: #FF7B40;
}

.color_2 {
  color: #FFAF32;
}

.color_3 {
  color: #4699E8;
}

.color_4 {
  color: #FB9424;
}

.color_5 {
  color: #F85A40;
}

.color_6 {
  color: #00C16E;
}

.color_7 {
  color: #A3A4A6;
}

.color_8 {
  color: #71757D;
}

.color_9 {
  color: #096dd9;
}

.color_8 {
  color: #71757D;
}

.blue_btn {
  background-color: #4699E8;
  border-color: #4699E8;
  color: #fff;
}

.bg_color_1 {
  background-color: #FF7B40;
}

.bg_color_2 {
  background-color: #FFAF32;
}