/**
 * @ Author: Your name
 * @ Create Time: 2023-07-10 09:50:49
 * @ Modified by: Your name
 * @ Modified time: 2024-07-23 09:50:58
 * @ Description:
 */

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
}

.flex-justify-content {
  display: flex;
  justify-content: space-between;
}


.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cursor {
  cursor: pointer;
}