/**
 * @ Author: Your name
 * @ Create Time: 2023-07-10 09:47:37
 * @ Modified by: Your name
 * @ Modified time: 2023-12-21 19:40:14
 * @ Description: 重置组件样式
 */

.custom_tabs_styles {
  .ant-tabs-nav {
    padding-left: 16px;
    margin-bottom: 0 !important;
  }

  .padding_left_16 {
    padding: 0 16px;
  }

  .custom_radio {
    .ant-checkbox-inner {
      border-radius: 50% !important;
    }
  }

  .ant-input::placeholder {
    color: #A3A4A6 !important;
  }

  .ant-input-affix-wrapper {
    border: 1px solid #DEDEDE;
  }
}


.custom_table {
  padding: 0 16px;
}

#customTable {
  .ant-table-placeholder {
    height: 100px;
  }
}

.custome-label .label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 3px;
}

.ant-menu-sub {
  background: #2C2C2C !important;
}

.no-colon .ant-form-item-label>label::after {
  content: "";
}

.ant-input[disabled],
.ant-input-number-disabled {
  color: rgba(102, 102, 102) !important;
  background-color: rgba(204, 204, 204, .2) !important;
}

.ant-picker-input>input[disabled] {
  color: rgba(102, 102, 102) !important;
  background: rgba(204, 204, 204, .2) !important;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(102, 102, 102) !important;
  background-color: rgba(204, 204, 204, .2) !important;
}

.ant-tree-checkbox-inner,
.ant-checkbox-inner {
  border-radius: 0 !important;
}

// .table-wrapper .ant-table .ant-table-thead .ant-table-cell-fix-right-first {
//   right: 0 !important;
// }